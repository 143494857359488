import { Component } from '@angular/core';
import { Platform, ModalController ,MenuController} from '@ionic/angular';
import { StaticDataService } from './services/local-storage/static-data.service';
import { Storage } from '@ionic/storage';
import { StorageService } from './services/local-storage/storage.service';
import { AlertServiceService } from './services/alert/alert-service.service';
import { FCM } from '@ionic-native/fcm/ngx';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(
    private platform: Platform,
    public stData: StaticDataService,
    private storage: Storage,
    private localStorage: StorageService,
    private fcm: FCM,
    private alert: AlertServiceService,
    private router: Router,
    private modalCtrl: ModalController,
    private location: Location,
    private menu:MenuController,
  ) {
	 
    this.initialize();
    this.platform.ready().then(() => {
      this.platform.backButton.subscribeWithPriority(999, async () => {
        if (this.modalCtrl.getTop()) {
          const modal = await this.modalCtrl.getTop();
          if (modal) {
            this.modalCtrl.dismiss();
            return;
          } else {
            this.localStorage.getObject(this.stData.storageName).then(val => {
              if (val) {
                if (this.router.url == "/home") {
                  navigator['app'].exitApp();
                } else {
                  this.location.back();
                }
              } else {
                if (this.router.url == "/login") {
                  navigator['app'].exitApp();
                }
              }
            });
          }
        } else {
          if (this.router.url == "/home") {
            navigator['app'].exitApp();
          } else {
            this.location.back();
          }
        }
      });
    });
  }



  initialize() {
    this.platform.ready().then(() => {
      console.log("App is ready to run")
      this.stData.isDevice = this.platform.is('cordova');
      this.storage.create().then(async e => {
        if (this.stData.isDevice) {
          await this.fcm.getToken().then(token => {
            console.log(token)
            this.stData.pushNotificationToken = token;
          });

          this.fcm.createNotificationChannelAndroid({
            id: 'fcm_default_channel',
            lights: false,
            name: 'My notification channel',
            sound:'assets/notification.mpeg',
            visibility: 'public'
          })
          this.fcm.onNotification().subscribe(data => {
            console.log(data);
            // alert(JSON.stringify(data));
            if (data.wasTapped) {
              console.log('Received in background');
              if (data.page == "new-delivery") {
                this.stData.routeChange('/new-delivery/' + Number(data.orderId))
              }
            } else {
              console.log('Received in foreground');
              this.presentToastWithOptions(data);
            }
          });

          this.fcm.onTokenRefresh().subscribe(token => {
            console.log(token)
            this.stData.pushNotificationToken = token;
          });
        }
      });
    });
  }



  /**
   * This function is called to view toast messege in app
   * @param ev 
   */
  presentToastWithOptions(ev: any) {
    this.alert.pushNotificationPresentToast(ev);
  }



  /**
   * This function is called to logiut
   * Developer:Debraj Chakraborty
   */
  sessionLogout() {
    this.menu.toggle();
    this.localStorage.logout();
  }



  menuTooggle(url:any){
    this.menu.toggle();
    this.stData.routeChange(url)
  }
}
