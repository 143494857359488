import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './services/middlewares/auth-guard.service'
const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: '',
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'home',
        loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
      },
      {
        path: 'login',
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule)
      },
      {
        path: 'order-list',
        loadChildren: () => import('./pages/order-master/order-list/order-list.module').then(m => m.OrderListPageModule)
      },
      {
        path: 'new-delivery/:orderid',
        loadChildren: () => import('./pages/delivery-master/new-delivery/new-delivery.module').then(m => m.NewDeliveryPageModule)
      },
      {
        path: 'notification-list',
        loadChildren: () => import('./pages/notification-master/notification-list/notification-list.module').then(m => m.NotificationListPageModule)
      },
      {
        path: 'notification-details',
        loadChildren: () => import('./pages/notification-master/notification-details/notification-details.module').then(m => m.NotificationDetailsPageModule)
      },
      {
        path: 'user-list',
        loadChildren: () => import('./pages/user-master/user-list/user-list.module').then(m => m.UserListPageModule)
      },
      {
        path: 'add-user',
        loadChildren: () => import('./pages/user-master/add-user/add-user.module').then(m => m.AddUserPageModule)
      },
      {
        path: 'site-setting',
        loadChildren: () => import('./pages/app-setting-master/site-setting/site-setting.module').then(m => m.SiteSettingPageModule)
      },
      {
        path: 'site-group-setting',
        loadChildren: () => import('./pages/app-setting-master/site-group-setting/site-group-setting.module').then(m => m.SiteGroupSettingPageModule)
      },
      {
        path: 'add-site-setting',
        loadChildren: () => import('./pages/app-setting-master/add-site-setting/add-site-setting.module').then(m => m.AddSiteSettingPageModule)
      },
      {
        path: 'add-site-group-setting',
        loadChildren: () => import('./pages/app-setting-master/add-site-group-setting/add-site-group-setting.module').then(m => m.AddSiteGroupSettingPageModule)
      },
      {
        path: 'product-list',
        loadChildren: () => import('./pages/catalogue/product-list/product-list.module').then(m => m.ProductListPageModule)
      },
      {
        path: 'product',
        loadChildren: () => import('./pages/catalogue/manage-products/manage-products.module').then(m => m.ManageProductsPageModule)
      },
      {
        path: 'product/:productId',
        loadChildren: () => import('./pages/catalogue/manage-products/manage-products.module').then(m => m.ManageProductsPageModule)
      },
      {
        path: 'add-related-product',
        loadChildren: () => import('./pages/catalogue/add-related-product/add-related-product.module').then(m => m.AddRelatedProductPageModule)
      },
      {
        path: 'customer-list',
        loadChildren: () => import('./pages/customer-master/customer-list/customer-list.module').then(m => m.CustomerListPageModule)
      },
      {
        path: 'add-customer',
        loadChildren: () => import('./pages/customer-master/add-customer/add-customer.module').then(m => m.AddCustomerPageModule)
      },
      {
        path: 'customer-details/:customerId',
        loadChildren: () => import('./pages/customer-master/customer-details/customer-details.module').then(m => m.CustomerDetailsPageModule)
      },
      {
        path: 'category-list',
        loadChildren: () => import('./pages/catalogue/category-list/category-list.module').then(m => m.CategoryListPageModule)
      },
      {
        path: 'category-details/:categoryId',
        loadChildren: () => import('./pages/catalogue/category-details/category-details.module').then(m => m.CategoryDetailsPageModule)
      },
      {
        path: 'add-category',
        loadChildren: () => import('./pages/catalogue/add-category/add-category.module').then(m => m.AddCategoryPageModule)
      },
      {
        path: 'add-customer-address',
        loadChildren: () => import('./pages/customer-master/add-customer-address/add-customer-address.module').then(m => m.AddCustomerAddressPageModule)
      },
      {
        path: 'sub-category-list/:categoryId',
        loadChildren: () => import('./pages/catalogue/sub-category-list/sub-category-list.module').then(m => m.SubCategoryListPageModule)
      },
      {
        path: 'catalogue-price-rule',
        loadChildren: () => import('./pages/promotion-master/catalogue-price-rule/catalogue-price-rule.module').then(m => m.CataloguePriceRulePageModule)
      },
      {
        path: 'catalogue-price-rule-details',
        loadChildren: () => import('./pages/promotion-master/catalogue-price-rule-details/catalogue-price-rule-details.module').then(m => m.CataloguePriceRuleDetailsPageModule)
      },
      {
        path: 'catalogue-price-rule-details/:id',
        loadChildren: () => import('./pages/promotion-master/catalogue-price-rule-details/catalogue-price-rule-details.module').then(m => m.CataloguePriceRuleDetailsPageModule)
      },
      {
        path: 'cart-price-rule',
        loadChildren: () => import('./pages/promotion-master/cart-price-rule/cart-price-rule.module').then(m => m.CartPriceRulePageModule)
      },
      {
        path: 'cart-price-rule-details',
        loadChildren: () => import('./pages/promotion-master/cart-price-rule-details/cart-price-rule-details.module').then(m => m.CartPriceRuleDetailsPageModule)
      },
      {
        path: 'cart-price-rule-details/:id',
        loadChildren: () => import('./pages/promotion-master/cart-price-rule-details/cart-price-rule-details.module').then(m => m.CartPriceRuleDetailsPageModule)
      },
      {
        path: 'order-home-view',
        loadChildren: () => import('./pages/order-master/order-home-view/order-home-view.module').then(m => m.OrderHomeViewPageModule)
      },
      {
        path: 'create-order',
        loadChildren: () => import('./pages/order-master/create-order/create-order.module').then(m => m.CreateOrderPageModule)
      },
      {
        path: 'add-new-product-order',
        loadChildren: () => import('./pages/order-master/add-new-product-order/add-new-product-order.module').then(m => m.AddNewProductOrderPageModule)
      },
      {
        path: 'order-details',
        loadChildren: () => import('./pages/order-master/order-details/order-details.module').then(m => m.OrderDetailsPageModule)
      },
      {
        path: 'dynamic-form-list',
        loadChildren: () => import('./pages/cms-master/dynamic-form/dynamic-form-list/dynamic-form-list.module').then(m => m.DynamicFormListPageModule)
      },
      {
        path: 'dynamic-form-data/:id',
        loadChildren: () => import('./pages/cms-master/dynamic-form/dynamic-form-data/dynamic-form-data.module').then(m => m.DynamicFormDataPageModule)
      },
      {
        path: 'dynamic-form-data-details',
        loadChildren: () => import('./pages/cms-master/dynamic-form/dynamic-form-data-details/dynamic-form-data-details.module').then(m => m.DynamicFormDataDetailsPageModule)
      },
      {
        path: 'cms-module-list',
        loadChildren: () => import('./pages/cms-master/cms-pages/cms-module-list/cms-module-list.module').then(m => m.CmsModuleListPageModule)
      },
      {
        path: 'cms-page-list/:slug',
        loadChildren: () => import('./pages/cms-master/cms-pages/cms-page-list/cms-page-list.module').then(m => m.CmsPageListPageModule)
      },
      {
        path: 'add-cms-page',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-cms-page/add-cms-page.module').then(m => m.AddCmsPagePageModule)
      },
      {
        path: 'cms-page-details/:slug',
        loadChildren: () => import('./pages/cms-master/cms-pages/cms-page-details/cms-page-details.module').then(m => m.CmsPageDetailsPageModule)
      },
      {
        path: 'cms-page-submenu-list/:slug',
        loadChildren: () => import('./pages/cms-master/cms-pages/cms-page-submenu-list/cms-page-submenu-list.module').then(m => m.CmsPageSubmenuListPageModule)
      },
      {
        path: 'add-content-block',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-content-block/add-content-block.module').then(m => m.AddContentBlockPageModule)
      },
      {
        path: 'cms-page-menu-list',
        loadChildren: () => import('./pages/cms-master/cms-pages/cms-page-menu-list/cms-page-menu-list.module').then(m => m.CmsPageMenuListPageModule)
      },
      {
        path: 'add-cms-menu',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-cms-menu/add-cms-menu.module').then(m => m.AddCmsMenuPageModule)
      },
      {
        path: 'add-cms-module',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-cms-module/add-cms-module.module').then(m => m.AddCmsModulePageModule)
      },
      {
        path: 'blog',
        loadChildren: () => import('./pages/cms-master/cms-pages/blog/blog.module').then(m => m.BlogPageModule)
      },
      {
        path: 'author',
        loadChildren: () => import('./pages/cms-master/cms-pages/author/author.module').then(m => m.AuthorPageModule)
      },
      {
        path: 'delivery-timeslot',
        loadChildren: () => import('./pages/delivery-master/delivery-timeslot/delivery-timeslot.module').then(m => m.DeliveryTimeslotPageModule)
      },
      {
        path: 'reservetion-timeslot',
        loadChildren: () => import('./pages/delivery-master/reservetion-timeslot/reservetion-timeslot.module').then(m => m.ReservetionTimeslotPageModule)
      },
      // {
      //   path: 'blog',
      //   loadChildren: () => import('./pages/cms-master/cms-pages/blog/blog.module').then(m => m.BlogPageModule)
      // },
      {
        path: 'author',
        loadChildren: () => import('./pages/cms-master/cms-pages/author/author.module').then(m => m.AuthorPageModule)
      },
      {
        path: 'add-author',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-author/add-author.module').then(m => m.AddAuthorPageModule)
      },
      {
        path: 'add-category',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-category/add-category.module').then(m => m.AddCategoryPageModule)
      },
      {
        path: 'category',
        loadChildren: () => import('./pages/cms-master/cms-pages/category/category.module').then(m => m.CategoryPageModule)
      },
      {
        path: 'add-blog',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-blog/add-blog.module').then(m => m.AddBlogPageModule)
      },
      {
        path: 'add-timeslot',
        loadChildren: () => import('./pages/delivery-master/add-timeslot/add-timeslot.module').then(m => m.AddTimeslotPageModule)
      },
      {
        path: 'add-reservetion',
        loadChildren: () => import('./pages/delivery-master/add-reservetion/add-reservetion.module').then(m => m.AddReservetionPageModule)
      },
      {
        path: 'blog-details/:slug',
        loadChildren: () => import('./pages/cms-master/cms-pages/blog-details/blog-details.module').then(m => m.BlogDetailsPageModule)
      },
      {
        path: 'survey-list',
        loadChildren: () => import('./pages/survey-master/survey-list/survey-list.module').then(m => m.SurveyListPageModule)
      },
      {
        path: 'survey-details/:slug',
        loadChildren: () => import('./pages/survey-master/survey-details/survey-details.module').then(m => m.SurveyDetailsPageModule)
      },
      {
        path: 'add-survey',
        loadChildren: () => import('./pages/survey-master/add-survey/add-survey.module').then(m => m.AddSurveyPageModule)
      },
      {
        path: 'add-answer-option',
        loadChildren: () => import('./pages/survey-master/add-answer-option/add-answer-option.module').then(m => m.AddAnswerOptionPageModule)
      },
      {
        path: 'answer-option-list',
        loadChildren: () => import('./pages/survey-master/answer-option-list/answer-option-list.module').then(m => m.AnswerOptionListPageModule)
      },
      {
        path: 'survey-result-list',
        loadChildren: () => import('./pages/survey-master/survey-result-list/survey-result-list.module').then(m => m.SurveyResultListPageModule)
      },
      {
        path: 'survey-result-details/:slug',
        loadChildren: () => import('./pages/survey-master/survey-result-details/survey-result-details.module').then(m => m.SurveyResultDetailsPageModule)
      },
      {
        path: 'sub-section/:slug',
        loadChildren: () => import('./pages/cms-master/cms-pages/sub-section/sub-section.module').then(m => m.SubSectionPageModule)
      },
      {
        path: 'billing-feedback',
        loadChildren: () => import('./pages/feedback/billing-feedback/billing-feedback.module').then(m => m.BillingFeedbackPageModule)
      },
      {
        path: 'contact-us-details',
        loadChildren: () => import('./pages/cms-master/contact-us-details/contact-us-details.module').then(m => m.ContactUsDetailsPageModule)
      },
      {
        path: 'contact-us-list',
        loadChildren: () => import('./pages/cms-master/contact-us-list/contact-us-list.module').then(m => m.ContactUsListPageModule)
      },

      {
        path: 'add-sub-section',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-sub-section/add-sub-section.module').then(m => m.AddSubSectionPageModule)
      },
      {
        path: 'billing-feedback-details',
        loadChildren: () => import('./pages/feedback/billing-feedback-details/billing-feedback-details.module').then(m => m.BillingFeedbackDetailsPageModule)
      },
      {
        path: 'call-back-list',
        loadChildren: () => import('./pages/call-back-list/call-back-list.module').then(m => m.CallBackListPageModule)
      },
      {
        path: 'forgot-password/:host/:utext',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
      },
      {
        path: 'chat-list',
        loadChildren: () => import('./pages/chat-master/chat-list/chat-list.module').then(m => m.ChatListPageModule)
      },
      {
        path: 'chat-setting',
        loadChildren: () => import('./pages/chat-master/chat-setting/chat-setting.module').then(m => m.ChatSettingPageModule)
      },
      {
        path: 'add-chat-setting',
        loadChildren: () => import('./pages/chat-master/add-chat-setting/add-chat-setting.module').then(m => m.AddChatSettingPageModule)
      },
      {
        path: 'add-chat-group-setting',
        loadChildren: () => import('./pages/chat-master/add-chat-group-setting/add-chat-group-setting.module').then(m => m.AddChatGroupSettingPageModule)
      },
      {
        path: 'chat-group-setting',
        loadChildren: () => import('./pages/chat-master/chat-group-setting/chat-group-setting.module').then(m => m.ChatGroupSettingPageModule)
      },
      {
        path: 'razorpay',
        loadChildren: () => import('./pages/razorpay/razorpay.module').then(m => m.RazorpayPageModule)
      },
      {
        path: 'banners/:id',
        loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersPageModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersPageModule)
      },
      {
        path: 'banners-list/:id',
        loadChildren: () => import('./pages/banners-list/banners-list.module').then(m => m.BannersListPageModule)
      },
      {
        path: 'banners-list',
        loadChildren: () => import('./pages/banners-list/banners-list.module').then(m => m.BannersListPageModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
      },
      {
        path: 'online-store-dashboard',
        loadChildren: () => import('./pages/online_store/online-store-dashboard/online-store-dashboard.module').then(m => m.OnlineStoreDashboardPageModule)
      },
      {
        path: 'analytics',
        loadChildren: () => import('./pages/analytics/analytics.module').then(m => m.AnalyticsPageModule)
      },
      {
        path: 'add-to-cut',
        loadChildren: () => import('./pages/product/add-to-cut/add-to-cut.module').then(m => m.AddToCutPageModule)
      },
      {
        path: 'subscribed',
        loadChildren: () => import('./pages/product/subscribed/subscribed.module').then(m => m.SubscribedPageModule)
      },
      {
        path: 'generate-pdf/:data/:proction/:storeId',
        loadChildren: () => import('./pages/online_store/generate-pdf/generate-pdf.module').then(m => m.GeneratePdfPageModule)
      },
    

      {
        path: 'add-sub-section',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-sub-section/add-sub-section.module').then(m => m.AddSubSectionPageModule)
      },
      {
        path: 'billing-feedback-details',
        loadChildren: () => import('./pages/feedback/billing-feedback-details/billing-feedback-details.module').then(m => m.BillingFeedbackDetailsPageModule)
      },
      {
        path: 'call-back-list',
        loadChildren: () => import('./pages/call-back-list/call-back-list.module').then(m => m.CallBackListPageModule)
      },
      {
        path: 'forgot-password/:host/:utext',
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordPageModule)
      },
      {
        path: 'chat-list',
        loadChildren: () => import('./pages/chat-master/chat-list/chat-list.module').then(m => m.ChatListPageModule)
      },
      {
        path: 'chat-setting',
        loadChildren: () => import('./pages/chat-master/chat-setting/chat-setting.module').then(m => m.ChatSettingPageModule)
      },
      {
        path: 'add-chat-setting',
        loadChildren: () => import('./pages/chat-master/add-chat-setting/add-chat-setting.module').then(m => m.AddChatSettingPageModule)
      },
      {
        path: 'add-chat-group-setting',
        loadChildren: () => import('./pages/chat-master/add-chat-group-setting/add-chat-group-setting.module').then(m => m.AddChatGroupSettingPageModule)
      },
      {
        path: 'chat-group-setting',
        loadChildren: () => import('./pages/chat-master/chat-group-setting/chat-group-setting.module').then(m => m.ChatGroupSettingPageModule)
      },
      {
        path: 'razorpay',
        loadChildren: () => import('./pages/razorpay/razorpay.module').then(m => m.RazorpayPageModule)
      },
      {
        path: 'banners/:id',
        loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersPageModule)
      },
      {
        path: 'banners',
        loadChildren: () => import('./pages/banners/banners.module').then(m => m.BannersPageModule)
      },
      {
        path: 'banners-list/:id',
        loadChildren: () => import('./pages/banners-list/banners-list.module').then(m => m.BannersListPageModule)
      },
      {
        path: 'banners-list',
        loadChildren: () => import('./pages/banners-list/banners-list.module').then(m => m.BannersListPageModule)
      },
      {
        path: 'privacy-policy',
        loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyPageModule)
      },
      {
        path: 'online-store-dashboard',
        loadChildren: () => import('./pages/online_store/online-store-dashboard/online-store-dashboard.module').then(m => m.OnlineStoreDashboardPageModule)
      },
      {
        path: 'property-list',
        loadChildren: () => import('./pages/property-master/property-list/property-list.module').then(m => m.PropertyListPageModule)
      },
      {
        path: 'add-edit-property',
        loadChildren: () => import('./pages/property-master/add-edit-property/add-edit-property.module').then(m => m.AddEditPropertyPageModule)
      },
      {
        path: 'games-list/:id',
        loadChildren: () => import('./pages/games-master/games-list/games-list.module').then(m => m.GamesListPageModule)
      },
      {
        path: 'slot-rules/:id/:pid',
        loadChildren: () => import('./pages/slot-master/slot-rules/slot-rules.module').then(m => m.SlotRulesPageModule)
      },
      {
        path: 'view-slots/:id',
        loadChildren: () => import('./pages/slot-master/view-slots/view-slots.module').then(m => m.ViewSlotsPageModule)
      },
      {
        path: 'slot-booking',
        loadChildren: () => import('./pages/slot-master/slot-booking/slot-booking.module').then(m => m.SlotBookingPageModule)
      },
      {
        path: 'view-booking',
        loadChildren: () => import('./pages/games-master/view-booking/view-booking.module').then( m => m.ViewBookingPageModule)
      },
      {
        path: 'games-booking/:id',
        loadChildren: () => import('./pages/games-master/games-booking/games-booking.module').then( m => m.GamesBookingPageModule)
      },
      {
        path: 'add-edit-game-modal',
        loadChildren: () => import('./pages/games-master/add-edit-game-modal/add-edit-game-modal.module').then( m => m.AddEditGameModalPageModule)
      },
      {
        path: 'property-gallery/:sl',
        loadChildren: () => import('./pages/property-master/property-gallery/property-gallery.module').then( m => m.PropertyGalleryPageModule)
      },
      {
        path: 'add-property-gallery',
        loadChildren: () => import('./pages/property-master/add-property-gallery/add-property-gallery.module').then( m => m.AddPropertyGalleryPageModule)
      },
      {
        path: 'partner-request-list',
        loadChildren: () => import('./pages/turf-partner-request-list/turf-partner-request-list.module').then( m => m.TurfPartnerRequestListPageModule)
      },
      {
        path: 'cart-list',
        loadChildren: () => import('./pages/order-master/cart-list/cart-list.module').then( m => m.CartListPageModule)
      },
      {
        path: 'catr-details',
        loadChildren: () => import('./pages/order-master/catr-details/catr-details.module').then( m => m.CatrDetailsPageModule)
      },
      {
        path: 'catr-details/:customerId',
        loadChildren: () => import('./pages/order-master/catr-details/catr-details.module').then( m => m.CatrDetailsPageModule)
      },
      {
        path: 'aaaa',
        loadChildren: () => import('./pages/order-master/aaaa/aaaa.module').then( m => m.AaaaPageModule)
      },
      {
        path: 'brand-list',
        loadChildren: () => import('./pages/brands/brand-list/brand-list.module').then( m => m.BrandListPageModule)
      },
      {
        path: 'brand-add-edit',
        loadChildren: () => import('./pages/brands/brand-add-edit/brand-add-edit.module').then( m => m.BrandAddEditPageModule)
      },
      {
        path: 'bulk-upload-list',
        loadChildren: () => import('./pages/cms-master/bulk-upload-list/bulk-upload-list.module').then( m => m.BulkUploadListPageModule)
      },
      {
        path: 'bulk-upload-add',
        loadChildren: () => import('./pages/cms-master/bulk-upload-add/bulk-upload-add.module').then( m => m.BulkUploadAddPageModule)
      },
      {
        path: 'bulk-upload-add-option',
        loadChildren: () => import('./pages/cms-master/bulk-upload-add-option/bulk-upload-add-option.module').then( m => m.BulkUploadAddOptionPageModule)
      },
      {
        path: 'bulk-upload-add-option-product',
        loadChildren: () => import('./pages/cms-master/bulk-upload-add-option-product/bulk-upload-add-option-product.module').then( m => m.BulkUploadAddOptionProductPageModule)
      },
      {
        path: 'product-export-page',
        loadChildren: () => import('./pages/cms-master/product-export-page/product-export-page.module').then( m => m.ProductExportPagePageModule)
      },
      {
        path: 'work',
        loadChildren: () => import('./pages/cms-master/cms-pages/work/work.module').then(m => m.WorkPageModule)
      },
      {
        path: 'add-work',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-work/add-work.module').then( m => m.AddWorkPageModule)
      },
      {
        path: 'work-page-list/:id',
        loadChildren: () => import('./pages/cms-master/cms-pages/work-page-list/work-page-list.module').then( m => m.WorkPageListPageModule)
      },
      {
        path: 'add-work-details',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-work-details/add-work-details.module').then( m => m.AddWorkDetailsPageModule)
      },
      
      {
        path: 'payment-method',
        loadChildren: () => import('./pages/payment-method/payment-method.module').then( m => m.PaymentMethodPageModule)
      },
      {
        path: 'product-collection',
        loadChildren: () => import('./pages/catalogue/product-collection/product-collection.module').then( m => m.ProductCollectionPageModule)
      },
      {
        path: 'manage-collection/:id',
        loadChildren: () => import('./pages/catalogue/manage-collection/manage-collection.module').then( m => m.ManageCollectionPageModule)
      },
      {
        path: 'carriers',
        loadChildren: () => import('./pages/cms-master/cms-pages/carriers/carriers.module').then( m => m.CarriersPageModule)
      },
      {
        path: 'add-carriers',
        loadChildren: () => import('./pages/cms-master/cms-pages/add-carriers/add-carriers.module').then( m => m.AddCarriersPageModule)
      },
      {
        path: 'carrier-details/:id',
        loadChildren: () => import('./pages/cms-master/cms-pages/carrier-details/carrier-details.module').then( m => m.CarrierDetailsPageModule)
      },
      {
        path: 'team',
        loadChildren: () => import('./pages/cms-master/cms-pages/team/team.module').then( m => m.TeamPageModule)
      },
      {
        path: 'payment-configuration',
        loadChildren: () => import('./pages/payment-configuration/payment-configuration.module').then( m => m.PaymentConfigurationPageModule)
      },   {
        path: 'customize-delivery',
        loadChildren: () => import('./pages/delivery-master/customize-delivery/customize-delivery.module').then( m => m.CustomizeDeliveryPageModule)
      },
      {
        path: 'new-delivery-invoice/:id',
        loadChildren: () => import('./pages/delivery-master/new-delivery-invoice/new-delivery-invoice.module').then( m => m.NewDeliveryInvoicePageModule)
      },
      {
        path: 'landing-pages',
        loadChildren: () => import('./pages/cms-master/landingpage-master/landing-pages/landing-pages.module').then( m => m.LandingPagesPageModule)
      },
      {
        path: 'add-edit-landingpage',
        loadChildren: () => import('./pages/cms-master/landingpage-master/add-edit-landingpage/add-edit-landingpage.module').then( m => m.AddEditLandingpagePageModule)
      },
      {
        path: 'landingpage-sections/:landingPageId',
        loadChildren: () => import('./pages/cms-master/landingpage-master/landingpage-sections/landingpage-sections.module').then( m => m.LandingpageSectionsPageModule)
      },
      {
        path: 'add-edit-landingpage-sections',
        loadChildren: () => import('./pages/cms-master/landingpage-master/add-edit-landingpage-sections/add-edit-landingpage-sections.module').then( m => m.AddEditLandingpageSectionsPageModule)
      },
    ]
  },

 
 
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
