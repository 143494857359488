import { NgModule , CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { IonicStorageModule } from '@ionic/storage-angular';
import { HttpClientModule } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { Camera } from '@ionic-native/camera/ngx';
import { Geolocation } from "@ionic-native/geolocation/ngx";
import { FCM } from '@ionic-native/fcm/ngx';
//import { SearchPipe } from './pipes/search.pipe';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
//import { NgxPaginationModule } from 'ngx-pagination';
//import { MdtohtmlPipe } from './pipe/mdtohtml.pipe';
const config: SocketIoConfig = {
	url: 'https://wip.tezcommerce.com:3304', // socket server url;
  //url: 'http://localhost:3309', // socket server url;
	options: {}
}
@NgModule({
  declarations: [AppComponent
    //SearchPipe,
   // MdtohtmlPipe
  ],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    IonicStorageModule.forRoot(),
    HttpClientModule,
   // NgxPaginationModule,
    SocketIoModule.forRoot(config),
  ],
  providers: [
    Geolocation,
    Camera,
    DatePipe,
    FCM,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule { }
