import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { StaticDataService } from '../local-storage/static-data.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  storeId:any;
  constructor(
    private storage: Storage,
    private stData: StaticDataService
  ) { }



  /**
   * this function is called to set data in key value pair from storage
   * Developer: Debraj Chakraborty
   */
  async set(key: string, value: any): Promise<any> {
    try {
      const result = await this.storage.set(key, value);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }



  /**
   * this function is called to get data in key value pair from storage
   * Developer: Debraj Chakraborty
   */
  async get(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      if (result != null) {
        return result;
      }
      return null;
    } catch (error) {
      console.log(error);
      return null;
    }
  }



  /**
   * this function is called to set a object in key value pair
   * Developer: Debraj Chakraborty
   */
  async setObject(key: string, object: Object) {
    try {
      const result = await this.storage.set(key, JSON.stringify(object));
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }



  /**
   * this function is called to get a object in key value pair
   * Developer: Debraj Chakraborty
   */
  async getObject(key: string): Promise<any> {
    try {
      const result = await this.storage.get(key);
      // console.log(result)
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }



  /**
   * this function is called to get Login data
   * Developer: Debraj Chakraborty
   */
   async getLoginData(): Promise<any> {
    try {
      const result = await this.storage.get(this.stData.storageName);
      await this.get_all_Details();
      // console.log(result)
      if (result != null) {
        return result;
      }
      return null;
    } catch (reason) {
      console.log(reason);
      return null;
    }
  }



  /**
   * this function is called to get user details
   * Developer: Debraj Chakraborty
   */
  async get_all_Details() {
    try {
      await this.getObject(this.stData.storageName).then((resu) => {
        const sess = JSON.parse(JSON.stringify(resu));
        console.log("sess",sess);
        if (sess) {
          this.stData.sessionUserLoggedin = true;
          this.stData.sessionUserId = sess.id;
          this.stData.sessionUserName = sess.name;
          this.stData.sessionUserEmail = sess.email;
          this.stData.sessionUserMobile = sess.phone;
          this.stData.storeId = sess.storeId;
          this.stData.sessionUserAddress = sess.address;
          this.stData.sessionUserImage = sess.image;
          this.stData.permissions = sess.permissions;
          this.storeId = sess.storeId;
		  /*---RoleCheck start---*/
		  	  this.stData.pCatalogue = !!sess.permissions.find(permission => {
                          return permission === 'catalogue'
                        });
			   this.stData.pOrders = !!sess.permissions.find(permission => {
                          return permission === 'orders'
                        });
			  this.stData.pReports = !!sess.permissions.find(permission => {
                          return permission === 'reports'
                        });
			 this.stData.pOffersAndPromotions = !!sess.permissions.find(permission => {
                          return permission === 'offers-and-promotions'
                        });
			this.stData.pCustomers = !!sess.permissions.find(permission => {
                          return permission === 'customers'
                        });
			this.stData.pUsers = !!sess.permissions.find(permission => {
                          return permission === 'users'
                        });
		   this.stData.pCms = !!sess.permissions.find(permission => {
                          return permission === 'cms'
                        });
		  this.stData.pSurvey = !!sess.permissions.find(permission => {
                          return permission === 'survey'
                        });
		 this.stData.pSetting = !!sess.permissions.find(permission => {
                          return permission === 'setting'
                        });
		this.stData.pBillingFeedback = !!sess.permissions.find(permission => {
                          return permission === 'billing-feedback'
                        });
		this.stData.pCallBack = !!sess.permissions.find(permission => {
		  return permission === 'call-back'
		});
		this.stData.pRazorpay = !!sess.permissions.find(permission => {
		  return permission === 'razorpay'
		});
		this.stData.pForm = !!sess.permissions.find(permission => {
		  return permission === 'forms'
    });
    this.stData.pTest = !!sess.permissions.find(permission => {
		  return permission === 'test'
		});
    this.stData.pTurf = !!sess.permissions.find(permission => {
		  return permission === 'turf'
		});
    this.stData.PonlineStore = !!sess.permissions.find(permission => {
		  return permission === 'online-store'
		});
    this.stData.Pbulk = !!sess.permissions.find(permission => {
		  return permission === 'bulk-upload'
		});
    this.stData.pContact = !!sess.permissions.find(permission => {
		  return permission === 'contacts'
		});
    this.stData.pChat = !!sess.permissions.find(permission => {
		  return permission === 'chat'
		});
    this.stData.pSubscribed = !!sess.permissions.find(permission => {
		  return permission === 'subscribed'
		});
    this.stData.pSaree = !!sess.permissions.find(permission => {
		  return permission === 'collection'
		});
		  /*---END --------*/
        } else {
          this.stData.sessionUserLoggedin = false;
          this.stData.sessionUserId = '';
          this.stData.sessionUserName = '';
          this.stData.sessionUserEmail = '';
          this.stData.sessionUserMobile = '';
          this.stData.storeId = '';
          this.stData.sessionUserAddress = '';
          this.stData.sessionUserImage = '';
		  this.stData.permissions = [];
        }
      });
    } catch (reason) {
      console.log(reason);
    }
  }



  /**
   * Logout a user from the session
   * Developer: Debraj Chakraborty
   */
  async logout() {
    await this.storage.remove(this.stData.storageName).then((sessdata) => {
      // console.log(sessdata)
      this.storage.clear();
      this.stData.sessionUserLoggedin = false;
      this.stData.sessionUserId = '';
      this.stData.sessionUserName = '';
      this.stData.sessionUserEmail = '';
      this.stData.sessionUserMobile = '';
      this.stData.storeId = '';
      this.stData.pushNotificationToken = '';
      this.stData.routeChange('login');
    });
  }
}
