import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class StaticDataService {
  isDevice: boolean;
  storeId: any;
  designation: any;
 // backendUrl = "https://partnerapp.tezcommerce.com:3310/"  ; 
  backendUrl= "https://wip.tezcommerce.com:3304/";
  // backendUrl = "http://localhost:3310/";
  apiversionUrl = "api/v1/";
  baseUrl = this.backendUrl + this.apiversionUrl;
  storageName = 'vendorApp_session_user';
  maxOtpSendTime = 60;
  sessionUserLoggedin: boolean = false;
  sessionUserId: any;
  sessionUserName: any;
  sessionUserEmail: any;
  sessionUserMobile: any;
  sessionUserAddress: any;
  sessionUserImage: any;
  pushNotificationToken:any;
  permissions:any = [];
  date = new Date();
  month = this.date.getMonth() + 1
  storeStorageName = this.date.getDate()+'/'+this.month+'/'+this.date.getFullYear()+'/store';

  pCatalogue:boolean = true;
  pOrders:boolean = true;
  pReports:boolean = true;
  pOffersAndPromotions:boolean = true;
  pCustomers:boolean = true;
  pUsers:boolean = true;
  pCms:boolean = true;
  pSurvey:boolean = true;
  pSetting:boolean = true;
  pBillingFeedback:boolean = true;
  pCallBack:boolean = true;
  pForm:boolean = true;
  pTest:boolean = true;
  pRazorpay:boolean = true;
  pTurf:boolean = true;
  PonlineStore:boolean = true;
  Pbulk:boolean = true;
  pContact: boolean = true;
  pChat: boolean = true;
  pSubscribed: boolean = true;
  pSaree: boolean;
  
  constructor(
    private route:Router
  ) { }


/**
 * This function is called to change router
 * @param url
 * Developer:Debraj Chakraborty
 */
  routeChange(url:string){
    this.route.navigateByUrl(url);
    console.log(url);
  }
 
  
}
