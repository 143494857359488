import { Injectable } from '@angular/core';
import { ToastController, AlertController } from '@ionic/angular';
import { StaticDataService } from '../local-storage/static-data.service';
@Injectable({
  providedIn: 'root'
})
export class AlertServiceService {

  constructor(
    private alert: AlertController,
    private toast: ToastController,
    private stData: StaticDataService
  ) { }



  /**
  * this function is called to display error alert 
  * Developer: Debraj Chakraborty
  */
  async errorAlert(msg: string) {
    if (this.stData.isDevice) {
      this.presentToast(msg);
    } else {
      const alert = await this.alert.create({
        header: 'Error!',
        message: msg,
        cssClass: 'langDelete',
        buttons: [
          {
            text: 'Ok',
            cssClass: 'secondary',
            role: "cancel"
          }
        ]
      });
      await alert.present();
    }
  }



  /**
   * this function is called to display success alert 
   * Developer: Debraj Chakraborty
   */
  async successAlert(msg: string) {
    if (this.stData.isDevice) {
      this.presentToast(msg);
    } else {
      const alert = await this.alert.create({
        header: 'Success',
        message: msg,
        cssClass: 'langDelete',
        buttons: [
          {
            text: 'Ok',
            cssClass: 'secondary',
            role: "cancel"
          }
        ]
      });
      await alert.present();
    }
  }



  /**
   * this function is called to display success alert 
   * Developer: Debraj Chakraborty
   */
  async customAlert(header: string, msg: string) {
    if (this.stData.isDevice) {
      this.presentToast(msg);
    } else {
      const alert = await this.alert.create({
        header: header,
        message: msg,
        cssClass: 'langDelete',
        buttons: [
          {
            text: 'Ok',
            cssClass: 'secondary',
            role: "cancel"
          }
        ]
      });
      await alert.present();
    }
  }



  /**
   * this function is called to display Toast msg 
   * Developer: Debraj Chakraborty
   */
  async presentToast(msg: string) {
    const toast = await this.toast.create({
      message: msg,
      duration: 2000
    });
    toast.present();
  }



  /**
   * this function is call to go notification
   * @param data 
   * Developer:Debraj Chakraborty
   */
  async pushNotificationPresentToast(data:any) {
    console.log(data)
      const toast = await this.toast.create({
        header: data.title,
        message: data.body,
        position: 'bottom',
        duration: 5000,
        buttons: [
          {
          text: 'Open',
          role: 'cancel',
          handler: () => {
                if(data.page=="new-delivery"){ 
                  this.stData.routeChange('/new-delivery/'+Number(data.orderId))
                }
              }
          }
        ]
      });
      toast.present();
  }
  /**
   * Dev: Chandan
   * Desc: success alert link with routing link.
   * @param msg 
   * @param link 
   */
  async successAlertWithLink(msg: string,link) {
   
    const alert = await this.alert.create({
      header: 'Success',
      message: msg,
      cssClass: 'langDelete',
      buttons: [
        {
          text: 'Ok',
          cssClass: 'secondary',
          handler: () => {
                if(link){ 
                  window.open(link,'_blank')
                }
          }
        }
        
      ]
    });
    await alert.present();
  
  }
}
