import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate,Router,RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { Platform } from '@ionic/angular';
import { StorageService } from '../local-storage/storage.service';
import { StaticDataService } from '../local-storage/static-data.service';
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(
    private platform: Platform,
    private storage: StorageService,
    private staticData: StaticDataService,
    private route : Router
  ) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const that = this;

    return new Observable(observer => {
      that.platform.ready().then(() => {
        that.storage.getObject(this.staticData.storageName).then(async val => {
          await that.storage.get_all_Details();
          if (val) {
            if (state.url == '/login') {
              observer.next(true);
              that.staticData.routeChange('home');
            } else {
              observer.next(true);
            }
          
          } else {
            const path: any = that.route.url;
            const path1: string[] = that.platform.url().split(path);
            const url = path1[3];
            console.log(url)
            if(url == 'forgot-password')
              observer.next(true);
            else { 
              observer.next(true);
              that.staticData.routeChange('login');
            }
          }
        });
      });
    });
  }
}
